import axios from 'axios'
import Cookies from 'js-cookie'
import {message} from 'antd';
import {IHORSEAI_URL, OSS_URL} from '../config';

export const OSS = "oss";

// axios.defaults.baseURL = IHORSEAI_URL

export function request(baseUrl) {
  const service = axios.create({
    baseURL: baseUrl,
    timeout: 10000
  })
  // 返回值处理
  service.interceptors.response.use(response => {
    if (response.status === 200) {
      return response.data
    } else {
      message.error(response.statusText)
      return false
    }
  }, error => {
    if (error.response) {
      if (error.response.status === 400) {
        message.error('400: ' + error.response.data.msg)
      } else if (error.response.status === 401) {
        $cookies.remove('adminToken');
        window.location.href = '/login'
        message.error('401: ' + error.response.data.msg)
        return Promise.reject(error.response)
      } else if (error.response.status === 403) {
        message.error('禁止访问：403: ' + error.response.data.msg)
      } else if (error.response.status === 500) {
        message.error('服务错误：500: ' + error.response.data.data)
      }
    }
    return Promise.reject(error.response)
  })
  return service;
}


/**
 *  封装axios的json post请求
 * @param {*} url 请求的接口路径
 * @param {*} params 请求的参数，参数格式以json对象形式
 * @param {*} appendToken  添加token
 */
export function postHeaderTokenFormApi(url, params, appendToken = true) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: url,
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        'token': $cookies.get('adminToken'),
        'userId': localStorage.getItem('userId'),
      },
      params
    }).then(response => {
      resolve(response)
    }).catch((error) => {
      reject(error)
    })
  })
}

/**
 *  封装axios的json GET请求
 * @param {*} url 请求的接口路径
 * @param {*} params 请求的参数，参数格式以json对象形式
 * @param {*} appendToken  添加token
 */
export function getHeaderTokenReplayFormApi(url, params, appendToken = true) {
  let baseUrl
  if (url.includes('oss')) {
    baseUrl = OSS_URL
  } else {
    baseUrl = IHORSEAI_URL
  }
  const axios = request(baseUrl)
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: url,
      headers: {
        'token': "REPLAY",
      },
      params
    }).then(response => {
      resolve(response)
    }).catch((error) => {
      reject(error)
    })
  })
}

/**
 *  封装axios的json GET请求
 * @param {*} url 请求的接口路径
 * @param {*} params 请求的参数，参数格式以json对象形式
 * @param {*} appendToken  添加token
 */
export function getHeaderTokenFormApi(url, params, appendToken = true) {
  let baseUrl
  if (url.includes('oss')) {
    baseUrl = OSS_URL
  } else {
    baseUrl = IHORSEAI_URL
  }
  const axios = request(baseUrl)
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: url,
      headers: {
        'token': $cookies.get('adminToken'),
        'userId': localStorage.getItem('userId')
      },
      params
    }).then(response => {
      resolve(response)
    }).catch((error) => {
      reject(error)
    })
  })
}

/**
 *  封装axios的json post请求
 * @param {*} url 请求的接口路径
 * @param {*} params 请求的参数，参数格式以json对象形式
 * @param {*} appendToken  添加token
 */
export function postHeaderTokenJsonTrade(url, params, appendToken = true) {
  let baseUrl
  if (url.includes('oss')) {
    baseUrl = OSS_URL
  } else {
    baseUrl = IHORSEAI_URL
  }
  const axios = request(baseUrl)
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: url,
      headers: {
        'Content-type': 'application/json',
        'token': $cookies.get('adminToken'),
        'userId': localStorage.getItem('userId'),
      },
      data: JSON.stringify(params)
    }).then(response => {
      resolve(response)
    }).catch((error) => {
      reject(error)
    })
  })
}

/**
 *  封装axios的file post请求
 * @param {*} url 请求的接口路径
 * @param {*} params 请求的参数，参数格式以json对象形式
 * @param {*} appendToken  添加token
 */
export function postHeaderTokenFileTrade(url, params, appendToken = true) {
  let baseUrl
  if (url.includes('oss')) {
    baseUrl = OSS_URL
  } else {
    baseUrl = IHORSEAI_URL
  }
  const axios = request(baseUrl)
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: url,
      headers: {
        'Content-type': 'multipart/form-data',
        'token': $cookies.get('adminToken'),
      },
      data: params
    }).then(response => {
      resolve(response)
    }).catch((error) => {
      reject(error)
    })
  })
}


/**
 *  封装axios的json get请求 拼接路径
 * @param {*} url 请求的接口路径
 * @param {*} params 请求的参数，参数格式以json对象形式
 * @param {*} appendToken  添加token
 */
export function getHeaderTokenJsonJoin(url, params, appendToken = true) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: url,
      headers: {
        'token': $cookies.get('adminToken'),
        'userId': localStorage.getItem('userId')
      },
      data: JSON.stringify(params)
    }).then(response => {
      resolve(response)
    }).catch((error) => {
      reject(error)
    })
  })
}


/**
 *  封装axios的json post请求
 * @param {*} url 请求的接口路径
 * @param {*} params 请求的参数，参数格式以json对象形式
 * @param {*} appendToken  添加token
 */
export function postHeaderTokenJsonApi(url, params, appendToken = true) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: url,
      headers: {
        'Content-type': 'application/json',
        'token': Cookies.get('token')
      },
      data: JSON.stringify(params)
    }).then(response => {
      resolve(response)
    }).catch((error) => {
      reject(error)
    })
  })
}

