import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Drawer} from 'antd';
import TreeLmDbcList from "./TreeLmDbcList";


export default class Meta extends Component {

  static propTypes = {
    handleClose: PropTypes.func,
    visible: PropTypes.bool,
    onihorseaiDbcSelected: PropTypes.func.isRequired,
    onihorseaiVideoSelected: PropTypes.func.isRequired,
  };


  constructor(props) {
    super(props);

  }

  render() {

    return (
      <Drawer
        title="ihorseai replay data"
        placement='left'
        width={500}
        onClose={this.props.handleClose}
        visible={this.props.visible}>
        <TreeLmDbcList
          onihorseaiDbcSelected={this.props.onihorseaiDbcSelected}
          onihorseaiVideoSelected={this.props.onihorseaiVideoSelected}
        />
      </Drawer>
    );
  }

}
