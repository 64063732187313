import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Hls from '@commaai/hls.js';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export default class LmVideo extends Component {
  static propTypes = {
    source: PropTypes.string.isRequired,
    startTime: PropTypes.number.isRequired,
    playbackSpeed: PropTypes.number.isRequired,
    playing: PropTypes.bool.isRequired,
    onVideoElementAvailable: PropTypes.func,
    onClick: PropTypes.func,
    onLoadStart: PropTypes.func,
    onLoadEnd: PropTypes.func,
    onPlaySeek: PropTypes.func,
    onRestart: PropTypes.func
  };

  componentDidUpdate(prevProps) {
    this.player.playbackSpeed = this.props.playbackSpeed;
    if (prevProps.source !== this.props.source) {
      this.loadSource(this.props.source);
    }
    if (this.props.playing) {
      if (this.player && (this.player.paused || this.player.currentTime() < 0.01)) {
        this.player.play();
      }
    } else {
      this.player.pause();
    }
  }

  componentDidMount() {
    // options
    const video_url = this.props.source;
    this.player = videojs(this.videoNode, {
      autoplay: true,
      controls: false,
      fluid: 'true',
      'playbackRates': [0.1, 0.5, 1, 2],
      sources: [{
        src: video_url,
        type: 'video/mp4'
      }],
      controlBar: {
        timeDivider: true,
        durationDisplay: true,
        remainingTimeDisplay: true,
        fullscreenToggle: true // 全屏按钮
      }
    });
  }

  onSeeking = () => {
    if (!this.props.playing) {
      this.props.onLoadStart();
      this.props.onPlaySeek(this.player.currentTime());
    }
  };

  // legacy outer scope variable. Revisit this to see if putting in state
  // makes more sense
  shouldInitVideoTime = true;

  onSeeked = () => {
    if (!this.props.playing) {
      this.props.onLoadEnd();
    }
  };

  onLoadedData = () => {
    if (this.shouldInitVideoTime) {
      // this.player.currentTime = this.props.startTime;
      this.shouldInitVideoTime = false;
    }
  };

  loadSource(source = this.props.source) {
    if (this.player) {
      this.player.loadSource(source)
      this.props.onVideoElementAvailable(this.player);
    }
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }

  render() {
    return (
      <div
        className="cabana-explorer-visuals-camera-wrapper"
        onClick={this.props.onClick}
      >
        {/*<video*/}
        {/*  // ref={(video) => {*/}
        {/*  //   this.videoElement = video;*/}
        {/*  // }}*/}
        {/*  src={this.props.source}*/}
        {/*  autoPlay={this.props.playing}*/}
        {/*  muted*/}
        {/*  onWaiting={this.props.onLoadStart}*/}
        {/*  onPlaying={this.props.onLoadEnd}*/}
        {/*  onSeeking={this.onSeeking}*/}
        {/*  onSeeked={this.onSeeked}*/}
        {/*  onLoadedData={this.onLoadedData}*/}
        {/*/>*/}

        <div className="fullscreen">
          <video ref={node => this.videoNode = node}
                 className="video-js"
                 muted
                 autoPlay={this.props.playing}
                 onWaiting={this.props.onLoadStart}
                 onPlaying={this.props.onLoadEnd}
                 onSeeking={this.onSeeking}
                 onSeeked={this.onSeeked}
                 onLoadedData={this.onLoadedData}>

          </video>
        </div>
      </div>
    );
  }
}
