import {
  getHeaderTokenReplayFormApi,
} from "./axios";

import {
  getFilesByPrefixUrl,
} from "./url"
import {OSS_URL} from "../config";

export const getFilesByPrefix = (params) => {
  return getHeaderTokenReplayFormApi(getFilesByPrefixUrl, params);
}
export const getOssApi = (url, params) => {
  return getHeaderTokenReplayFormApi(OSS_URL + url, params);
}


