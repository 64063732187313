import React, {Component} from 'react';
import {Tree} from 'antd';
import {getFilesByPrefix, getOssApi} from "../api/ihorseaiDbc";
import DBC from "../models/can/dbc";
import PropTypes from 'prop-types';

const {DirectoryTree} = Tree;

export default class TreeLmDbcList extends Component {

  static propTypes = {
    onihorseaiDbcSelected: PropTypes.func.isRequired,
    onihorseaiVideoSelected: PropTypes.func.isRequired,
  };


  constructor(props) {
    super(props);

    this.state = {
      treeData: [],
      updateData: []
    };

    this.onLoadData = this.onLoadData.bind(this);
  }

  componentDidMount() {
    this.loadFilesByPrefix(50, null, 'replay/')
  }

  loadFilesByPrefix(maxKeys, nextContinuationToken, prefix) {
    var param;
    if (nextContinuationToken === null) {
      param = {
        maxKeys: maxKeys,
        prefix: prefix
      };
    } else {
      param = {
        nextContinuationToken: nextContinuationToken,
        maxKeys: maxKeys,
        prefix: prefix
      }
    }
    getFilesByPrefix(param).then((res) => {
      if (res.code === 200) {
        const treeData = []
        res.data.fileTrees.map((file) => {
          treeData.push({
            title: file.label,
            key: file.prefix,
            isLeaf: file.file,
            isFile: file.file,
            url: file.pathUrl,
          })
        })
        this.setState({treeData})
      }
    })
  }

  updateTreeData = (list, key, children) =>
    list.map((node) => {
      if (node.key === key) {
        return {...node, children};
      }
      if (node.children) {
        return {...node, children: this.updateTreeData(node.children, key, children)};
      }

      return node;
    });


  onLoadData = (treeNode) => {
    return new Promise((resolve) => {
      if (treeNode.children) {
        resolve();
        return;
      }
      getFilesByPrefix({
        maxKeys: 50,
        prefix: treeNode.key
      }).then((res) => {
        if (res.code === 200) {
          const childrenData = []
          res.data.fileTrees.map((file) => {
            childrenData.push({
              title: file.label,
              key: file.prefix,
              isLeaf: file.file,
              isFile: file.file,
              url: file.pathUrl,
            })
          })
          const treeData = this.updateTreeData(this.state.treeData, treeNode.key, childrenData)
          this.setState({treeData})
          resolve();
        }
      })
    });
  };

  onSelect = async (keys, info) => {
    console.log('Trigger Select', keys, info.node);
    if (info.node.isFile) {
      if (info.node.key.includes(".dbc")) {
        getOssApi(info.node.key).then((dbcText) => {
          const dbc = new DBC(dbcText);
          this.props.onihorseaiDbcSelected(info.node.title, dbc);
        })
      } else if (info.node.key.includes(".ts") || info.node.key.includes(".mp4")) {
        this.props.onihorseaiVideoSelected(info.node.url);
      }
    }

  };


  render() {
    return (
      <DirectoryTree
        multiple
        defaultExpandAll
        switcherIcon={null}
        onSelect={this.onSelect}
        loadData={this.onLoadData}
        treeData={this.state.treeData}/>
    );
  }

}
