import React from 'react';
import ReactDOM from 'react-dom';
import CanExplorer from './CanExplorer';
import init from './init';
import './index.css';

import {ConfigProvider, Button} from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import 'antd/dist/antd.css';

// init().then((props) => {
//   ReactDOM.render(<CanExplorer {...props} />, document.getElementById('root')); // eslint-disable-line react/jsx-props-no-spreading
// });

init().then((props) => {
  ReactDOM.render(<ConfigProvider locale={zhCN}><CanExplorer {...props} /></ConfigProvider>,
    document.getElementById('root')); // eslint-disable-line react/jsx-props-no-spreading
});



